@import url('https://fonts.googleapis.com/css?family=Aldrich|Khula|Nanum+Pen+Script&display=swap');

$colour-primary: black;
$colour-secondary: #cc0000;
$colour-tertiary: #fff;

$font-primary: 'Montserrat';
$font-secondary: 'Nanum Pen Script';
$font-tertiary: 'Aldrich';

html,
body {
  margin: 0;
  overflow-x: hidden;
}

img {
  max-width: 100%;
}

.c-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: calc(100% - 30px);
  width: 1400px;
  padding: 0 15px;
  margin: auto;
}

.c-hero {
  display: flex;
  align-items: center;
  justify-items: center;
  height: 100%;
  width: 100%;

  &__wrapper {
    display: flex;
    width: 100%;
  }

  &__img {
    width: 550px;
    max-width: 100%;
    margin: 20px auto;
  }
}

.c-banner {
  padding: 50px 0px;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-items: center;
    width: 100%;
  }
}

.c-search {
  font-family: $font-secondary;
  font-weight: 700;
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 20px;
  position: relative;
  min-width: 100%;

  label {
    padding-left: 20px;
    color: purple;
    position: absolute;
    top: 7px;
    left: calc(50% + 115px);
  }
  input {
    border: none;
    border: 2px solid black;
    padding: 10px 15px;
    font-family: $font-primary;
    font-weight: 700;
    color: $colour-primary;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 1px;
    min-width: 200px;
  }
}

.c-options {
  width: 80%;
  margin: auto;
  min-width: 100%;

  &__wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media screen and (max-width: 600px) {
      flex-direction: column;
    }
  }

  &__option {
    width: 48%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 600px) {
      width: 100%;
      padding-bottom: 30px;
    }
  }

  &__title {
    transition: 0.15s ease-in;
    &:hover {
      fill: $colour-secondary;
      transform: scale(1.1);
    }
  }

  &__links {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    a {
      text-transform: uppercase;
      font-family: $font-primary;
      font-weight: 300;
      text-decoration: none;
      text-align: center;
      font-size: 18px;
      color: inherit;
      letter-spacing: 2px;
      padding: 5px 0;

      &:hover {
        //font-weight: 600;
        //text-decoration: underline;
        color: $colour-secondary;
      }
    }
  }
}

.c-nav {
  display: inline-flex;
  align-items: center;
  color: $colour-primary;
  font-family: $font-secondary;
  letter-spacing: 2px;
  list-style: none;
  font-size: 16px;
  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }

  &__item {
    position: relative;
    padding: 10px;
    text-transform: uppercase;
    width: 100px;
    text-align: center;

    @media screen and (max-width: 600px) {
      width: unset;
    }

    &--disabled {
      opacity: 0.3;
      pointer-events: none;

      @media screen and (max-width: 600px) {
        display: none;
      }
    }

    &--open {
      font-weight: 700;

      &::after {
        content: url(../img/before.svg);
        position: absolute;
        bottom: -10px;
        left: 10px;
        width: 80px;
        max-width: 50px;
      }
    }

    a {
      text-decoration: none;
      color: inherit;

      &:hover {
        font-weight: 700;

        &::after {
          content: url(../img/before.svg);
          position: absolute;
          bottom: -10px;
          left: 10px;
          width: 80px;
          max-width: 50px;
        }
      }
    }

    &--noafter {
      &::after {
        display: none;
      }
    }
  }
  &__logo {
    width: 75px;
  }
}
